<template>
  <v-snackbar v-model="snackbar" v-bind="context" data-testid="snackbar">
    {{ context.message }}
    <template #action>
      <v-btn @click="close" color="white" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const initData = () => ({
  right: true,
  bottom: true,
  timeout: 2000,
  elevation: '2',
  message: ''
});

export default {
  name: 'av-snackbar',
  data: () => ({
    snackbar: false,
    context: initData()
  }),
  methods: {
    show(message, { variant = 'primary', ...options } = {}) {
      Object.assign(this.context, { message, color: variant, ...options });
      this.snackbar = true;
    },
    close() {
      this.snackbar = false;
    }
  },
  watch: {
    snackbar(visible) {
      if (visible) return;
      this.context = initData();
    }
  }
};
</script>
