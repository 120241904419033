<template>
  <v-snackbar
    v-on="$listeners"
    :value="$attrs.value"
    :color="undefined"
    :timeout="timeout"
    :class="`${variant}-color`"
    top
    center
    class="av-snackbar">
    <slot name="content"></slot>
    <template #action>
      <slot name="action"></slot>
    </template>
  </v-snackbar>
</template>

<script>
import { VSnackbar } from 'vuetify/lib';

export const Variant = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning'
};

const variantValidator = val => Object.values(Variant).includes(val);

export default {
  name: 'av-snackbar',
  props: {
    variant: {
      type: String,
      default: Variant.INFO,
      validator: variantValidator
    },
    timeout: { type: Number, default: -1 }
  },
  components: { VSnackbar }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

$variants: (
  'success': (
    'background-color': var(--v-success-lighten5),
    'border-color': var(--v-secondary-base),
    'color': var(--v-secondary-base)
  ),
  'error': (
    'background-color': var(--v-error-lighten5),
    'border-color': var(--v-error-base),
    'color': var(--v-error-base)
  ),
  // Update warning colors when warning toast gets introduced
  'warning':
    (
      'background-color': var(--v-white-base),
      'border-color': var(--v-warning-base),
      'color': var(--v-warning-base)
    ),
  'info': (
    'background-color': var(--v-primary-lighten5),
    'border-color': var(--v-primary-base),
    'color': var(--v-primary-base)
  )
);

.av-snackbar {
  padding-top: 5.25rem;
  z-index: z-index(modal) + z-index(content);

  :deep(.v-snack__wrapper) {
    width: 100%;
    max-width: 71.375rem;
    box-shadow: $elevation-15;
  }

  :deep(.v-snack__content) {
    width: 21.625rem;
    padding: 1.25rem 2rem;
  }

  :deep(.v-snack__action) {
    margin: 0;
  }
}

@each $name, $color-map in $variants {
  .av-snackbar.#{$name}-color {
    :deep(.v-snack__wrapper) {
      background-color: map-get($color-map, 'background-color');
      border: 1px solid map-get($color-map, 'border-color') !important;
    }

    :deep(.v-snack__content) .prepend-icon.v-icon {
      color: map-get($color-map, 'color');
    }
  }
}
</style>
