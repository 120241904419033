export function navigate(path = '/') {
  const url = new URL(location);
  url.pathname = path;
  location.replace(url.href);
}

export function restoreScrollPosition(position) {
  if (position) return position;
  return { x: 0, y: 0 };
}
