import { extractData } from '@/common/api/helpers';
import request from './request';
import urljoin from 'url-join';

const urls = {
  base: '/identity',
  me: () => urljoin(urls.base, 'me'),
  authorize: () => urljoin(urls.base, 'authorize'),
  passwordChangeUrl: () => urljoin(urls.base, '/password-change-url'),
  verifyEmail: verificationToken =>
    urljoin(urls.base, 'verify-email', verificationToken)
};

function fetchCurrentIdentity() {
  return request.get(urls.me()).then(extractData);
}

function authorize(email) {
  return request.base.post(urls.authorize(), { email }).then(extractData);
}

function fetchPasswordChangeUrl({ returnUrl, ttl } = {}) {
  return request
    .get(urls.passwordChangeUrl(), {
      params: { returnUrl, ttl }
    })
    .then(extractData);
}

function verifyEmail(verificationToken) {
  return request.base.post(urls.verifyEmail(verificationToken));
}

export default {
  fetchCurrentIdentity,
  fetchPasswordChangeUrl,
  authorize,
  verifyEmail
};
