import request from './request';

const urls = {
  base: 'logs'
};

function info(message) {
  return request.sendBeacon(urls.base, { level: 'info', message });
}

function warn(message) {
  return request.sendBeacon(urls.base, { level: 'warn', message });
}

function error(message) {
  return request.sendBeacon(urls.base, { level: 'error', message });
}

export default { info, warn, error };
