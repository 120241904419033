import request from '@/common/api/request';

const urls = {
  learnerEvents: 'learner-events'
};

function sendLearnerEvent(data) {
  return request.sendBeacon(urls.learnerEvents, data);
}

export default {
  sendLearnerEvent
};
