import axios from 'axios';
import axiosRetry from 'axios-retry';
import { EventEmitter } from 'events';
import HttpStatus from 'http-status';
import isRetryAllowed from 'is-retry-allowed';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import urljoin from 'url-join';
import Vue from 'vue';

const baseUrl = new URL('api', process.env.VUE_APP_SERVER_URL);

const config = {
  baseURL: baseUrl.href,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params, { strictNullHandling: true })
};

class Auth extends EventEmitter {
  #accessToken;
  #accessTokenExpiresAt;
  #currentRefreshTokenPromise = null;

  constructor(storage = localStorage) {
    super();
    this.storage = storage;
  }

  get accessToken() {
    return this.#accessToken;
  }

  set accessToken(value) {
    if (!value) {
      this.#accessToken = null;
      this.#accessTokenExpiresAt = null;
      return;
    }
    // TODO return expiration date from refresh token API
    const { exp } = jwtDecode(value);
    this.#accessToken = value;
    this.#accessTokenExpiresAt = exp * 1000;
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  ensureFreshAccessToken() {
    if (this.accessToken && !this.isTokenExpired()) return Promise.resolve();
    if (!this.#currentRefreshTokenPromise) {
      this.#currentRefreshTokenPromise = this.refreshAccessToken();
    }
    return this.#currentRefreshTokenPromise;
  }

  refreshAccessToken() {
    return Vue.$auth
      .getAccessToken()
      .then(accessToken => this.setAccessToken(accessToken))
      .finally(() => {
        this.#currentRefreshTokenPromise = null;
      });
  }

  isTokenExpired() {
    return this.#accessTokenExpiresAt < Date.now();
  }
}

// Instance of axios to be used for all API requests.
const client = axios.create(config);
client.auth = new Auth();

Object.defineProperty(client, 'base', {
  get() {
    if (!this.base_) this.base_ = axios.create(config);
    return this.base_;
  }
});

client.sendBeacon = async function sendBeacon(url, data) {
  await client.auth.ensureFreshAccessToken();
  const payload = new Blob(
    [JSON.stringify({ accessToken: client.auth.accessToken, data })],
    { type: 'application/json' }
  );
  return navigator.sendBeacon(urljoin(config.baseURL, url), payload);
};

client.createRetryRequest = function ({ retries, retryDelay, retryCondition }) {
  const retryClient = client;
  axiosRetry(retryClient, { retries, retryDelay, retryCondition });
  return retryClient;
};

client.isNetworkError = function (error) {
  return (
    !error?.response?.status &&
    Boolean(error.code) &&
    error.code !== 'ECONNABORTED' &&
    isRetryAllowed(error)
  );
};

client.interceptors.request.use(setAuthorizationHeader);

client.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status !== HttpStatus.UNAUTHORIZED) throw err;
    client.auth.emit('error', err);
  }
);

export default client;

async function setAuthorizationHeader(config) {
  await client.auth.ensureFreshAccessToken();
  config.headers.Authorization = ['Bearer', client.auth.accessToken].join(' ');
  return config;
}
