import isNil from 'lodash/isNil';

export function extractData(res) {
  if (isNil(res.data.total)) return res.data.data;
  const { data, total } = res.data;
  return { items: data, total };
}

export function createPaginationParams({
  page = 1,
  itemsPerPage = 10,
  sortBy,
  sortAsc,
  params
} = {}) {
  const showAllItems = -1;
  const limit = itemsPerPage === showAllItems ? null : itemsPerPage;

  const paginationParams = {
    offset: (page - 1) * limit,
    limit
  };

  if (sortBy) {
    paginationParams.sortBy = Array.isArray(sortBy) ? sortBy : [sortBy];
    const isAsc = Array.isArray(sortAsc) ? sortAsc[0] : sortAsc;
    paginationParams.sortOrder = isAsc ? 'ASC' : 'DESC';
  }

  return Object.assign(paginationParams, params || {});
}
