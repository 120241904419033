import auth from '@/common/api/auth';
import Vue from 'vue';

export const logout = ({ state }) => {
  Vue.$auth.logout(state.user);
};

export const loginDemoUser = ({ state }) => {
  Vue.$auth.loginWithRedirect({
    screen_hint: 'signup',
    login_hint: state.user.email,
    prompt: 'login'
  });
};

export const fetchCurrentIdentity = ({ commit }) => {
  return auth
    .fetchCurrentIdentity()
    .then(user => commit('login', user) || user);
};

export const verifyEmail = async ({ commit, state }, verificationToken) => {
  await auth.verifyEmail(verificationToken);

  if (state.user) {
    commit('verifyEmail');
  }
};
