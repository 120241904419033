<template>
  <v-progress-circular
    v-bind="{ size, color, width }"
    indeterminate
    class="av-progress" />
</template>

<script>
import { VProgressCircular } from 'vuetify/lib';

export default {
  name: 'av-circular-progress',
  props: {
    color: { type: String, default: 'primary' },
    size: { type: [Number, String], default: 50 },
    width: { type: [Number, String], default: 4 }
  },
  components: { VProgressCircular }
};
</script>
