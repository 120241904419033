import { createPaginationParams, extractData } from '@/common/api/helpers';
import request from '@/common/api/request';

const urls = {
  base: '/users'
};

function fetch(params = {}) {
  return request
    .get(urls.base, { params: createPaginationParams(params) })
    .then(extractData);
}

export default {
  fetch
};
