export const LearnerEventType = {
  CATALOG_ITEM_VISITED: 'CATALOG_ITEM_VISITED',
  CATALOG_ITEM_LEFT: 'CATALOG_ITEM_LEFT',
  VIDEO_PLAYED: 'VIDEO_PLAYED',
  VIDEO_PAUSED: 'VIDEO_PAUSED',
  VIDEO_TIMEUPDATED: 'VIDEO_TIMEUPDATED',
  REPRODUCIBLES_DOWNLOADED: 'REPRODUCIBLES_DOWNLOADED',
  STRATEGY_APPLICATION_UPDATED: 'STRATEGY_APPLICATION_UPDATED',
  CATALOG_ITEM_RATED: 'CATALOG_ITEM_RATED',
  RUBRIC_SCORE_SAVED: 'RUBRIC_SCORE_SAVED',
  BOOKMARK_UPDATED: 'BOOKMARK_UPDATED',
  LEARNER_LOGIN: 'LEARNER_LOGIN'
};
