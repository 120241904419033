import debounce from 'lodash/debounce';
import DefaultSnackbar from './Snackbar';
import Queue from 'promise-queue';
import { Variant } from '@/common/components/base/Snackbar';

const queue = new Queue(1, Infinity);

export const install = (Vue, { component = DefaultSnackbar } = {}) => {
  const SnackbarCtor = Vue.extend(component);
  const vm = new SnackbarCtor();

  // Replace this later since mixin will not be supported in Vue 3.x
  Vue.mixin({
    mounted() {
      if (this.$options.name !== 'v-app') return;
      vm.$vuetify = this.$root.$vuetify;
      this.$el.appendChild(vm.$mount().$el);
    }
  });

  const toQueue = (msg, opts) => queue.add(() => vm.show(msg, opts));
  const debouncedQueue = debounce(toQueue, 2500, { leading: true });
  const show = (msg, opts) => {
    return (opts?.immediate ? toQueue : debouncedQueue)(msg, opts);
  };

  const snackbar = {
    show: (msg, opts) => show(msg, opts),
    close: () => vm.close,
    success: (msg, opts) => show(msg, { ...opts, variant: Variant.SUCCESS }),
    info: (msg, opts) => show(msg, { ...opts, variant: Variant.INFO }),
    warning: (msg, opts) => show(msg, { ...opts, variant: Variant.WARNING }),
    error: (msg, opts) => show(msg, { ...opts, variant: Variant.ERROR })
  };

  Vue.prototype.$snackbar = snackbar;
  Vue.snackbar = snackbar;
};

export default install;
