import request from '@/common/api/request';
import urljoin from 'url-join';

const urls = {
  base: '/organizations',
  checkByName: name => urljoin(urls.base, name)
};

function checkByName(name) {
  return request.head(urls.checkByName(name));
}

export default {
  checkByName
};
