import analyticsApi from '../../main/api/analytics';
import createAuth0Client from '@auth0/auth0-spa-js';
import { LearnerEventType } from '@/common/models/learner-event';
import request from '@/common/api/request';
import { Role } from '@/common/models/user';
import Vue from 'vue';

const { VUE_APP_AUTH0_DOMAIN, VUE_APP_AUTH0_API_IDENTIFIER } = process.env;

const LOCAL_TOKEN_NAME = 'AV_TOKEN';

const useAuth = ({
  redirectUri = window.location.origin,
  domain = VUE_APP_AUTH0_DOMAIN,
  audience = VUE_APP_AUTH0_API_IDENTIFIER,
  clientId,
  store,
  router
}) => {
  return new Vue({
    data: () => ({
      loading: true,
      isAuthenticated: false,
      auth0Client: null
    }),
    methods: {
      getLocalToken: () => localStorage.getItem(LOCAL_TOKEN_NAME),
      removeLocalToken: () => localStorage.removeItem(LOCAL_TOKEN_NAME),
      redirectToDemoLogin: () => window.location.assign('/reveal'),
      redirectToLocalLogin: () => window.location.assign('/login'),
      getAccessToken(options) {
        const localToken = this.getLocalToken();
        if (localToken) return Promise.resolve(localToken);
        return this.auth0Client.getTokenSilently(options);
      },
      isLocallyAuthenticated() {
        return !!this.getLocalToken();
      },
      isSSOAuthenticated() {
        return this.auth0Client.isAuthenticated();
      },
      loginWithRedirect(options) {
        return this.auth0Client.loginWithRedirect(options);
      },
      async loginLocalUser({ accessToken }) {
        localStorage.setItem(LOCAL_TOKEN_NAME, accessToken);
        await this.fetchCurrentIdentity();
        this.sendLoginEvent();
      },
      logout(user) {
        const isLocalAuth = !!this.getLocalToken();
        isLocalAuth && this.removeLocalToken();
        this.isAuthenticated = false;
        if (user?.role === Role.DEMO) return this.redirectToDemoLogin();
        if (isLocalAuth) return this.redirectToLocalLogin();
        return this.auth0Client.logout({ returnTo: redirectUri });
      },
      async handleRedirectCallback() {
        const { search } = window.location;
        const searchParams = new URLSearchParams(search);
        const hasError = searchParams.has('error');
        if (hasError) throw new Error(searchParams.get('error_description'));
        const isCallback =
          searchParams.has('code') && searchParams.has('state');
        if (!isCallback) return;
        const { appState } = await this.auth0Client.handleRedirectCallback();
        this.applyAppState(appState);
      },
      applyAppState({ targetUrl = window.location.pathname } = {}) {
        router.push(targetUrl).catch(() => {});
      },
      async fetchCurrentIdentity() {
        const accessToken = await this.getAccessToken();
        request.auth.setAccessToken(accessToken);
        await store.dispatch('auth/fetchCurrentIdentity');
      },
      sendLoginEvent() {
        const isAdmin = store.getters['auth/isAdmin'];
        if (isAdmin) return;
        const timeStamp = new Date();
        const payload = {
          type: LearnerEventType.LEARNER_LOGIN,
          timeStamp
        };
        return analyticsApi.sendLearnerEvent(payload);
      }
    },
    created() {
      return createAuth0Client({
        domain,
        client_id: clientId,
        audience,
        redirect_uri: redirectUri,
        useRefreshTokens: true
      })
        .then(auth0Client => {
          this.auth0Client = auth0Client;
        })
        .then(() => this.handleRedirectCallback())
        .then(async () => {
          const isSSOAuthenticated = await this.isSSOAuthenticated();
          const isLocallyAuthenticated = this.isLocallyAuthenticated();
          this.isAuthenticated = isSSOAuthenticated || isLocallyAuthenticated;
          if (!this.isAuthenticated) return;
          if (isSSOAuthenticated) this.removeLocalToken();
          await this.fetchCurrentIdentity();
          this.sendLoginEvent();
        })
        .catch(() => {
          // TODO: Send error message as query param to redirectUri and show it
          // on login page
          this.logout();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  });
};

export const AuthPlugin = {
  install(Vue, options = {}) {
    Vue.prototype.$auth = Vue.$auth = useAuth(options);
  }
};
